import React, { useRef } from 'react';
import { Spin } from "antd";
import styles from "./_styles.less";
import MiniTable from "../../../components/mini-table";
import { useTranslation } from "react-i18next";
import useLoading from "../../../hooks/useLoading";
import { consultasSelector, fetchConsultas, fetchConsultasPorStatus } from "../../../store/consultivoDashboard";
import { useDispatch, useSelector } from "react-redux";
import { openPopupConsultivoConsulta } from "../../../util/window";
import TableCellEllipsis from "../../../components/table-cell-ellipsis";


export default function ConsultasTabelaCard() {
  const dispatch = useDispatch();
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const loading = useLoading(fetchConsultas.typePrefix);
  const consultas = useSelector(consultasSelector);

  const columns = [
    {
      title: t('Consulta'),
      dataIndex: 'id',
      width: 80,
    },
    {
      title: t('Título'),
      dataIndex: 'titulo',
      render: (text) => <TableCellEllipsis text={text}/>
    },
  ]

  const onUnload = () => {
    dispatch(fetchConsultasPorStatus());
    dispatch(fetchConsultas());
  };

  const handleRowClick = consulta => {
    openPopupConsultivoConsulta(consulta, 'cadastro', { onUnload });
  };

  return (
    <Spin spinning={loading}>
      <div className={styles['table-card']}>
        <MiniTable
          ref={miniTableRef}
          columns={columns}
          dataSource={consultas}
          pagination={false}
          rowClassName={styles['row']}
          onRow={(record, rowIndex) => ({ onClick: () => handleRowClick(record) })}
          scroll={{ y: 230 }}
        />
      </div>
    </Spin>
  )
}

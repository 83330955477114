import { Spin } from 'antd';
import React from 'react';
import useFormatter from "../../../hooks/useFormatter";
import styles from './_styles.less'

export default function StatusCard({ count, loading, title, onClick }) {
  const { formatNumber } = useFormatter();

  const handleClick = e => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }

  return (
    <Spin spinning={loading}>
      <div
        className={styles['status-card']}
        onClick={handleClick}
        style={{ cursor: onClick ? 'pointer' : undefined }}
      >
        <div className={styles['title']}>
          {title}
        </div>
        <div className={styles['count']}>
          {formatNumber(count)}
        </div>
      </div>
    </Spin>);
}

import { InfoOutlined } from '@ant-design/icons';
import { Alert, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../components/action-bar';
import ActionButton from '../../../components/action-button';
import MiniTable from '../../../components/mini-table';
import useLoading from '../../../hooks/useLoading';
import { fetchPLs, plsAlertasSelector, plsSearchSelector, plsSelector } from '../../../store/empresa';
import { openPopup } from '../../../util/window';
import Alertas from '../_Alertas';
import styles from '../_styles.less';
import EmpresaPLsTable from './_table';
import useModulo from "../../../hooks/useModulo";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";

export default function EmpresaPLsDetailPage() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const pls = useSelector(plsSelector);
  const search = useSelector(plsSearchSelector);
  const alertas = useSelector(plsAlertasSelector);
  const loading = useLoading(fetchPLs.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const { moduloBasePath } = useModulo();
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSearch = search => {
    dispatch(fetchPLs({ empresaId, search }));
  };

  const handleDetail = pl => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/pls/${pl.id}`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/pls/`, `logs_/empresas/${empresaId}/pls/`);
  };

  const actions = [];
  actions.push(<ActionButton.Logs noTitle onClick={handleLogs}/>);
  actions.push(<MiniTable.DownloadButton disabled={loading || pls.length === 0} miniTableRef={miniTableRef}/>);
  const title = () => <ActionBar search={search} onSearch={handleSearch}>{actions}</ActionBar>;
  return (
    <Space direction="vertical" className={styles['space']}>
      <EmpresaPLsTable
        dataSource={pls}
        loading={loading}
        miniTableRef={miniTableRef}
        onClick={handleDetail}
        onSelectedIdsChange={setSelectedIds}
        selectedIds={selectedIds}
        title={title}
      />
      <Alert
        banner
        icon={<InfoOutlined/>}
        message={t('As informações fornecidas acima devem ser aquelas da data base indicada. Essas informações servirão como base para verificar a necessidade de apresentação de declarações ao Banco Central do Brasil')}
        type="info"
      />
      <Alertas alertas={alertas}/>
    </Space>);
}

EmpresaPLsDetailPage.propTypes = {
  pls: PropTypes.array,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
};

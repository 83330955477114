import AuthenticatedLayout from "../../../layouts/authenticated";
import AcessoMenu from "../../../components/acesso-menu";
import ConsultivoMenu from "../../../components/consultivo-menu";
import React, { useCallback, useEffect } from "react";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import { isClienteSelector } from "../../../store/autenticacao";
import ConsultasPanel from "./_ConsultasPanel";
import PesquisaPanel from "./_PesquisaPanel";
import styles from './_styles.less';
import { fetchConsultivo } from "../../../store/consultivo";
import { colapsarPainelDireito, colapsarPainelEsquerdo, fetchConsultas, filtrar, painelDireitoColapsadoSelector, painelEsquerdoColapsadoSelector, previewSelector, resetar } from "../../../store/consultivoPesquisa";
import PreviewPanel from "./_PreviewPanel";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useLocation } from "react-router";

export default function ConsultivoPesquisaPage() {
  const location = useLocation();
  const dispatch = useDispatchWithResult();
  const isCliente = useSelector(isClienteSelector);
  const painelEsquerdoColapsado = useSelector(painelEsquerdoColapsadoSelector);
  const painelDireitoColapsado = useSelector(painelDireitoColapsadoSelector);
  const consulta = useSelector(previewSelector);

  const painelEsquerdoWidth = painelEsquerdoColapsado ? '24px' : '320px';
  const painelDireitoWidth = (painelDireitoColapsado || !(consulta?.id > 0)) ? '24px' : '480px';

  useEffect(() => {
    dispatch(fetchConsultivo());
    dispatch(resetar());
    if (location.state) {
      dispatch(filtrar(location.state));
      dispatch(fetchConsultas());
      dispatch(colapsarPainelEsquerdo(true));
    }
  }, []);

  const handleEsquerdoLateralClick = useCallback(e => {
    e.preventDefault();
    dispatch(colapsarPainelEsquerdo(!painelEsquerdoColapsado));
  }, [painelEsquerdoColapsado]);

  const handleDireitoLateralClick = useCallback(e => {
    e.preventDefault();
    dispatch(colapsarPainelDireito(!painelDireitoColapsado));
  }, [painelDireitoColapsado]);

  return (
    <AuthenticatedLayout
      modulo="consultivo"
      menu={
        <>
          <AcessoMenu allowClear defaultRoute="/consultivo/pesquisa"/>
          <ConsultivoMenu showCreate={isCliente}/>
        </>
      }
      contentStyle={{ maxWidth: 'unset', padding: 0 }}
    >
      <div className={styles.ConsultivoPesquisaPage}>
        <div
          className={styles.painelEsquerdo}
          style={{
            width: painelEsquerdoWidth,
            minWidth: painelEsquerdoWidth,
            maxWidth: painelEsquerdoWidth,
          }}
        >
          <PesquisaPanel/>
          <div className={styles.lateral} onClick={handleEsquerdoLateralClick}>
            {!painelEsquerdoColapsado && <LeftOutlined/>}
            {painelEsquerdoColapsado && <RightOutlined/>}
          </div>
        </div>
        <div className={styles.painelCentral}>
          <ConsultasPanel/>
        </div>
        <div
          className={styles.painelDireito}
          style={{
            width: painelDireitoWidth,
            minWidth: painelDireitoWidth,
            maxWidth: painelDireitoWidth,
          }}
        >
          <div className={styles.lateral} onClick={handleDireitoLateralClick}>
            {!painelDireitoColapsado && <RightOutlined/>}
            {painelDireitoColapsado && <LeftOutlined/>}
          </div>
          <PreviewPanel/>
        </div>
      </div>
    </AuthenticatedLayout>)
}

import { PlusOutlined, TableOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import { acessoSelector } from '../../store/acessos';
import ActionLink from '../action-link';
import EmpresaDrawer from './_EmpresaDrawer';
import styles from './_styles.less';
import { funcoesSelector } from "../../store/autenticacao";
import { Dropdown, Menu } from "antd";
import { useHistory } from "react-router";
import ConsultaDrawer from "./_ConsultaDrawer";
import ProjetoDrawer from "./_ProjetoDrawer";
import * as consultivoDashboard from "../../store/consultivoOperacional";
import * as consultivoCartoes from "../../store/consultivoCartoes";

export default function ConsultivoMenu({ showCreate, extraMenu }) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const funcoes = useSelector(funcoesSelector);
  const acesso = useSelector(acessoSelector);
  const acessoId = acesso?.id;

  const [empresa, setEmpresa] = useState();
  const [empresaDrawerVisible, setEmpresaDrawerVisible] = useState(false);

  const [consulta, setConsulta] = useState();
  const [consultaDrawerVisible, setConsultaDrawerVisible] = useState(false);

  const [projeto, setProjeto] = useState();
  const [projetoDrawerVisible, setProjetoDrawerVisible] = useState(false);

  const hasCadastro = funcoes?.includes('cadastro') && acesso?.funcoes?.includes('cadastro');

  const handleCreateEmpresaClick = () => {
    setEmpresa({ acessoId });
    setEmpresaDrawerVisible(true);
  };
  const handleEmpresaDrawerClose = () => {
    setEmpresaDrawerVisible(false);
  };
  const handleEmpresaDrawerAfterClose = () => {
    setEmpresa(undefined);
  };

  const handleCreateConsultaClick = () => {
    setConsulta({ acessoId });
    setConsultaDrawerVisible(true);
  };
  const handleConsultaChange = () => {
    dispatch(consultivoDashboard.fetchConsultasPorStatus());
    dispatch(consultivoDashboard.fetchConsultas());
    dispatch(consultivoCartoes.fetchConsultasPorStatus());
  };
  const handleConsultaDrawerClose = () => {
    setConsultaDrawerVisible(false);
  };
  const handleConsultaDrawerAfterClose = () => {
    setConsulta(undefined);
  };

  const handleCreateProjetoClick = () => {
    setProjeto({});
    setProjetoDrawerVisible(true);
  };
  const handleProjetoChange = () => {
    // dispatch(fetchProjetos({}));
  };
  const handleProjetoDrawerClose = () => {
    setProjetoDrawerVisible(false);
  };
  const handleProjetoDrawerAfterClose = () => {
    setProjeto(undefined);
  };

  const handlePainelClick = ({ key }) => {
    history.push(key);
  };

  const buttons = [];
  if (showCreate) {
    buttons.push(
      <Dropdown
        key="create-dropdown"
        overlay={
          <Menu theme="dark">
            {hasCadastro && <Menu.Item onClick={handleCreateEmpresaClick}>{t('Criar Empresa')}</Menu.Item>}
            <Menu.Item onClick={handleCreateConsultaClick}>{t('Criar Consulta')}</Menu.Item>
            <Menu.Item onClick={handleCreateProjetoClick}>{t('Criar Projeto')}</Menu.Item>
          </Menu>
        }
      >
        <ActionLink key="create" icon={<PlusOutlined/>}/>
      </Dropdown>
    );
  }
  buttons.push(
    <Dropdown
      key="painel-dropdown"
      overlay={
        <Menu theme="dark" onClick={handlePainelClick}>
          <Menu.Item key="/consultivo/dashboard">{t('Painel')}</Menu.Item>
          <Menu.Item key="/consultivo/pesquisa">{t('Pesquisa')}</Menu.Item>
          <Menu.Item key="/consultivo/cartoes">{t('Cartões')}</Menu.Item>
          <Menu.Item key="/consultivo/operacional">{t('Operacional')}</Menu.Item>
        </Menu>
      }
    >
      <ActionLink key="painel" icon={<TableOutlined/>}/>
    </Dropdown>
  )
  if (extraMenu && extraMenu.length > 0)
    buttons.push(extraMenu);

  return (
    <div className={styles['consultivo-menu']}>
      {buttons}
      {hasCadastro &&
        <EmpresaDrawer
          value={empresa}
          visible={empresaDrawerVisible}
          onClose={handleEmpresaDrawerClose}
          onAfterClose={handleEmpresaDrawerAfterClose}
        />
      }
      <ConsultaDrawer
        value={consulta}
        onChange={handleConsultaChange}
        visible={consultaDrawerVisible}
        onClose={handleConsultaDrawerClose}
        onAfterClose={handleConsultaDrawerAfterClose}
      />
      <ProjetoDrawer
        value={projeto}
        onChange={handleProjetoChange}
        visible={projetoDrawerVisible}
        onClose={handleProjetoDrawerClose}
        onAfterClose={handleProjetoDrawerAfterClose}
      />
    </div>
  );
}

ConsultivoMenu.propTypes = {
  showCreate: PropTypes.bool,
  showFilter: PropTypes.bool,
  extraMenu: PropTypes.array,
};

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".qnL9tdhTFeN1TTmna9614g\\=\\= {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-grow: 1;\n  overflow-x: scroll;\n  padding: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/consultivo/cartoes/_ConsultasPorStatusCollection.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,2BAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;AACF","sourcesContent":[".ConsultasPorStatusCollection {\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: flex-start;\n  align-items: flex-start;\n  flex-grow: 1;\n  overflow-x: scroll;\n  padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ConsultasPorStatusCollection": "qnL9tdhTFeN1TTmna9614g=="
};
export default ___CSS_LOADER_EXPORT___;

import { FileTextFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useFormatter from '../../hooks/useFormatter';
import styles from './_styles.less';
import axios from "axios";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";

export default function TextAnexo({ label, name, tooltip, value }) {
  const { t } = useTranslation();
  const { formatBytes } = useFormatter();
  const [text, setText] = useState('');

  const style = {
    marginBottom: label ? undefined : '0'
  }

  const downloadDisabled = useMemo(() => !value, [value]);

  const handleViewAsText = useCallback(e => {
    e.preventDefault();
    unwrapAxiosPromise(axios.get(value?.url + '&output=text', { baseURL: '/' }))
      .then(data => setText(data));
  }, [value]);

  const valueFmt = value ?
    (name ?? value.name) + ' (' + formatBytes(value.size) + ')' :
    null;

  return (
    <div className={styles['text-anexo']}>
      <label>
        {t(label)}
        {tooltip &&
          <Tooltip title={tooltip}>
            <QuestionCircleOutlined className={styles['icon']}/>
          </Tooltip>
        }

        <p className={styles['value']} style={style}>
          {downloadDisabled && valueFmt}
          {!downloadDisabled &&
            <Row gutter={[8, 8]}>
              <Col>
                <a download={name ?? value?.name} href={value?.url}>
                  {valueFmt}
                </a>
              </Col>
              <Col>
                <a href="#" onClick={handleViewAsText}>
                  <FileTextFilled/>
                </a>
              </Col>
            </Row>
          }
        </p>
      </label>
      <Modal
        className="ws-pre-line"
        title={name ?? value?.name}
        footer={false}
        open={text?.length > 0}
        width={640}
        onCancel={() => setText('')}
      >
        {text}
      </Modal>
    </div>
  );
}

TextAnexo.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.object,
};

import { ApartmentOutlined, CheckOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, FilterFilled, FilterOutlined, FolderViewOutlined, HistoryOutlined, LayoutOutlined, PlusOutlined, QuestionCircleTwoTone } from '@ant-design/icons';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

function ActionLinkCreate({ to, ...props }) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = () => history.push(to);
  return (
    <ActionLink
      onClick={handleClick}
      title={t('Criar')}
      icon={<PlusOutlined/>}
      {...props}
    />
  );
}

ActionLinkCreate.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

function ActionLinkEdit({ to, ...props }) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = () => history.push(to);
  return (
    <ActionLink
      onClick={handleClick}
      title={t('Alterar')}
      icon={<EditOutlined/>}
      {...props}
    />
  );
}

ActionLinkEdit.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

function ActionLinkRemove({ onClick, ...props }) {
  const { t } = useTranslation();

  const handleConfirm = e => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      e.stopPropagation();
      const result = onClick();
      if (result instanceof Promise)
        result.catch(err => message.error(err.mensagem));
    }
  };

  const handleCancel = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Popconfirm
      title={t('Você confirma a exclusão do(s) registro(s) selecionado(s)?')}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      okText={t('Sim, excluir')}
      cancelText={t('Não excluir')}
      okButtonProps={{ type: 'danger' }}
      icon={<QuestionCircleTwoTone twoToneColor="#ff4d4f"/>}
      zIndex={10000}
    >
      <ActionLink
        danger
        title={t('Excluir')}
        icon={<DeleteOutlined/>}
        {...props}
      />
    </Popconfirm>
  );
}

ActionLinkRemove.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionLinkSave(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Salvar')}
      icon={<CheckOutlined/>}
      {...props}
    />
  );
}

ActionLinkSave.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionLinkLogs(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Logs')}
      icon={<HistoryOutlined/>}
      {...props}
    />
  );
}

ActionLinkLogs.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionLinkOrganograma(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Organograma')}
      icon={<ApartmentOutlined/>}
      {...props}
    />
  );
}

ActionLinkOrganograma.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionLinkLayout(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Layout')}
      icon={<LayoutOutlined/>}
      {...props}
    />
  );
}

ActionLinkLayout.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionLinkClose(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Fechar')}
      {...props}
    />
  );
}

ActionLinkClose.propTypes = {
  onClick: PropTypes.func,
};

function ActionLinkCancel(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Cancelar')}
      {...props}
    />
  );
}

ActionLinkCancel.propTypes = {
  onClick: PropTypes.func,
};

function ActionLinkFilter({ filtered, ...props }) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Filtro')}
      icon={filtered ? <FilterFilled/> : <FilterOutlined/>}
      {...props}
    />
  );
}

ActionLinkFilter.propTypes = {
  filtered: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionLinkDrive(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Drive')}
      icon={<FolderViewOutlined/>}
      {...props}
    />
  );
}

ActionLinkDrive.propTypes = {
  onClick: PropTypes.func,
};

function ActionLinkUpload(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Enviar Arquivo')}
      icon={<UploadOutlined/>}
      {...props}
    />
  );
}

ActionLinkUpload.propTypes = {
  onClick: PropTypes.func,
};

function ActionLinkDownload(props) {
  const { t } = useTranslation();
  return (
    <ActionLink
      title={t('Baixar Arquivo')}
      icon={<DownloadOutlined/>}
      {...props}
    />
  );
}

ActionLinkDownload.propTypes = {
  onClick: PropTypes.func,
};

function ActionLink({ danger, disabled, noTitle, loading, onClick, title, ...props }) {
  const handleClick = e => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  const button = (
    <Button
      danger={danger}
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
      type="link"
      children={noTitle ? undefined : title}
      {...props}
    />
  );

  if (noTitle)
    return (
      <Tooltip title={title}>
        {button}
      </Tooltip>);

  return button;
}

ActionLink.defaultProps = {
  danger: false,
  disabled: false,
  loading: false,
  noTitle: false,
};

ActionLink.propTypes = {
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

ActionLink.Create = ActionLinkCreate;
ActionLink.Edit = ActionLinkEdit;
ActionLink.Remove = ActionLinkRemove;
ActionLink.Save = ActionLinkSave;
ActionLink.Logs = ActionLinkLogs;
ActionLink.Organograma = ActionLinkOrganograma;
ActionLink.Layout = ActionLinkLayout;
ActionLink.Close = ActionLinkClose;
ActionLink.Cancel = ActionLinkCancel;
ActionLink.Filter = ActionLinkFilter;
ActionLink.Drive = ActionLinkDrive;
ActionLink.Upload = ActionLinkUpload;
ActionLink.Download = ActionLinkDownload;

export default ActionLink;

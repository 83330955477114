import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Flag from '../../components/flag';
import MiniTable from '../../components/mini-table';
import useFormatter from '../../hooks/useFormatter';
import { isSuperUsuarioSelector } from '../../store/autenticacao';
import styles from './_styles.less';
import { formatCnpj } from "../../util/cnpj";

function IpColumn({ ip, pais }) {
  return (
    <div className={styles['ip-column']}>
      <span>{ip}</span>
      <Tooltip title={pais?.nome}>
          <span className={styles['pais']}>
              <Flag countryCode={pais?.sigla}/>
          </span>
      </Tooltip>
    </div>
  );
}

export default function LogsTable({ dataSource, loading, miniTableRef, title, showAcesso, showEntidade }) {
  const { t } = useTranslation();
  const { formatDateTime } = useFormatter();
  const isSuperUsuario = useSelector(isSuperUsuarioSelector);

  const columns = [];

  if (showAcesso) {
    columns.push({
      title: t('Acesso'),
      dataIndex: ['acesso', 'nome'],
      key: 'acesso'
    })
  }

  if (showEntidade) {
    columns.push({
      title: t('Entidade'),
      key: 'entidade',
      render: (text, record) => {
        if (record.empresa)
          return 'Empresa ' + (record.empresa.apelido ?? record.empresa.razaoSocial) + ' (CNPJ ' + formatCnpj(record.empresa.cnpj) + ')';
        else if (record.acesso)
          return 'Acesso ' + record.nome;
        return record.entidade;
      },
    })
  }

  columns.push({
    title: t('Texto'),
    dataIndex: 'texto',
    key: 'texto',
  });
  columns.push({
    title: t('Por'),
    dataIndex: ['usuario', 'nome'],
    key: 'usuario',
    width: 220,
  });
  columns.push({
    title: t('Em'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 150,
    render: (text, record) => formatDateTime(record.createdAt),
  });

  if (isSuperUsuario) {
    columns.push({
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
      width: 100,
      render: (text, { ip, pais }) => <IpColumn ip={ip} pais={pais}/>,
    });
    columns.push({
      title: 'User-Agent',
      dataIndex: 'userAgent',
      key: 'userAgent',
      width: 100,
    });
  }

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
    />
  );
}

LogsTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  title: PropTypes.func,
};

import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { setAccessToken } from '../api';
import { decodeBase64String, encodeBase64String } from '../util/base64';

const initialState = {
  usuarioId: 0,
  email: '',
  nome: '',
  nivelAcesso: 0,
  ambienteId: 0,
  ambienteNome: '',
  ambienteModulos: [],
  ambienteIds: [],
  acessoIds: [],
  empresaIds: [],
  modulos: [],
  funcoes: [],
  emailMemorizado: decodeBase64String(localStorage.getItem('email')),
};

export const isAutenticadoSelector = state => state.autenticacao.usuarioId > 0;
export const usuarioIdSelector = state => state.autenticacao.usuarioId;
export const nomeSelector = state => state.autenticacao.nome;
export const nivelAcessoSelector = state => state.autenticacao.nivelAcesso;
export const ambienteIdSelector = state => state.autenticacao.ambienteId;
export const ambienteNomeSelector = state => state.autenticacao.ambienteNome;
export const ambienteModulosSelector = state => state.autenticacao.ambienteModulos;
export const ambienteIdsSelector = state => state.autenticacao.ambienteIds;
export const acessoIdsSelector = state => state.autenticacao.acessoIds;
export const empresaIdsSelector = state => state.autenticacao.empresaIds;
export const modulosSelector = state => state.autenticacao.modulos;
export const funcoesSelector = state => state.autenticacao.funcoes;
export const emailMemorizadoSelector = state => state.autenticacao.emailMemorizado;
export const lembrarSelector = state => !!state.autenticacao.emailMemorizado;

export const isClienteSelector = createSelector(isAutenticadoSelector, nivelAcessoSelector,
  (isAutenticado, nivelAcesso) => isAutenticado && nivelAcesso >= 0);
export const isClienteOperadorSelector = createSelector(isAutenticadoSelector, nivelAcessoSelector,
  (isAutenticado, nivelAcesso) => isAutenticado && nivelAcesso >= 1);
export const isClienteGestorSelector = createSelector(isAutenticadoSelector, nivelAcessoSelector,
  (isAutenticado, nivelAcesso) => isAutenticado && nivelAcesso >= 2);
export const isOperadorSelector = createSelector(isAutenticadoSelector, nivelAcessoSelector,
  (isAutenticado, nivelAcesso) => isAutenticado && nivelAcesso >= 3);
export const isGestorSelector = createSelector(isAutenticadoSelector, nivelAcessoSelector,
  (isAutenticado, nivelAcesso) => isAutenticado && nivelAcesso >= 4);
export const isDiretorSelector = createSelector(isAutenticadoSelector, nivelAcessoSelector,
  (isAutenticado, nivelAcesso) => isAutenticado && nivelAcesso >= 5);
export const isSuperUsuarioSelector = createSelector(isAutenticadoSelector, nivelAcessoSelector,
  (isAutenticado, nivelAcesso) => isAutenticado && nivelAcesso >= 7);

export const autenticar = createAsyncThunk(
  'autenticacao/autenticar',
  async ({ email, senha }, thunkAPI) => {
    try {
      const res = await axios.post('/autenticar', { email, senha }, { baseURL: '/' });
      setAccessToken(res.data.accessToken);
      await thunkAPI.dispatch(fetchUsuario());
      return Promise.resolve();
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const renovar = createAsyncThunk(
  'autenticacao/renovar',
  async (_, thunkAPI) => {
    try {
      const res = await axios.post('/renovar', null, { baseURL: '/' });
      setAccessToken(res.data.accessToken);
      await thunkAPI.dispatch(fetchUsuario());
      return Promise.resolve();
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const sair = createAsyncThunk(
  'autenticacao/sair',
  async (_, thunkAPI) => {
    try {
      await axios.post('/revogar', null, { baseURL: '/' });
      setAccessToken(false);
      return Promise.resolve();
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchUsuario = createAsyncThunk(
  'autenticacao/fetchUsuario',
  async (_, thunkAPI) => {
    try {
      const res = await axios.get('/autenticacao');
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const recuperarAcesso = createAsyncThunk(
  'autenticacao/recuperarAcesso',
  async ({ email }, thunkAPI) => {
    try {
      const res = await axios.post('/autenticacao/recuperar-acesso', { email });
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const redefinirSenha = createAsyncThunk(
  'autenticacao/redefinirSenha',
  async ({ passwordToken, email, novaSenha }, thunkAPI) => {
    try {
      const res = await axios.post('/autenticacao/redefinir-senha', { passwordToken, email, novaSenha });
      return res.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const slice = createSlice({
  name: 'autenticacao',
  initialState,
  reducers: {},
  extraReducers: {
    [autenticar.fulfilled]: (state, action) => {
      if (action.meta.arg.lembrar) {
        state.emailMemorizado = action.meta.arg.email;
        localStorage.setItem('email', encodeBase64String(state.emailMemorizado));
      } else {
        state.emailMemorizado = '';
        localStorage.removeItem('email');
      }
    },
    [fetchUsuario.fulfilled]: (state, action) => {
      state.usuarioId = action.payload.usuarioId;
      state.email = action.payload.email;
      state.nome = action.payload.nome;
      state.nivelAcesso = action.payload.nivelAcesso;
      state.ambienteId = action.payload.ambienteId;
      state.ambienteNome = action.payload.ambienteNome;
      state.ambienteModulos = action.payload.ambienteModulos;
      state.ambienteIds = action.payload.ambienteIds;
      state.acessoIds = action.payload.acessoIds;
      state.empresaIds = action.payload.empresaIds;
      state.modulos = action.payload.modulos;
      state.funcoes = action.payload.funcoes;
    },
    [fetchUsuario.rejected]: (state, action) => {
      state.usuarioId = 0;
      state.email = '';
      state.nome = '';
      state.nivelAcesso = 0;
      state.ambienteId = 0;
      state.ambienteNome = '';
      state.ambienteModulos = [];
      state.ambienteIds = [];
      state.acessoIds = [];
      state.empresaIds = [];
      state.modulos = [];
      state.funcoes = [];
    },
    [sair.pending]: (state, action) => {
      state.usuarioId = 0;
      state.email = '';
      state.nome = '';
      state.nivelAcesso = 0;
      state.ambienteId = 0;
      state.ambienteNome = '';
      state.ambienteModulos = [];
      state.ambienteIds = [];
      state.acessoIds = [];
      state.empresaIds = [];
      state.modulos = [];
      state.funcoes = [];
    },
  },
});

export default slice.reducer;

import React, { useRef } from 'react';
import { Col, Row, Spin } from "antd";
import FlexCard from "../../../components/flex-card";
import useLoading from "../../../hooks/useLoading";
import { consultasSelector, fetchConsultas, fetchConsultasPorStatus } from "../../../store/consultivoOperacional";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MiniTable from "../../../components/mini-table";
import styles from './_styles.less';
import { Consulta, findById } from "../../../constants";
import useConsultivo from "../../../hooks/useConsultivo";
import useFormatter from "../../../hooks/useFormatter";
import { openPopupConsultivoConsulta } from "../../../util/window";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";

export default function ConsultasCard() {
  const miniTableRef = useRef();
  const { t } = useTranslation();
  const { formatDate } = useFormatter();
  const loading = useLoading(fetchConsultas.typePrefix);
  const { getAreaCaminho } = useConsultivo();
  const dispatch = useDispatchWithResult();
  const consultas = useSelector(consultasSelector);

  const columns = [
    {
      title: t('Empresa'),
      dataIndex: ['empresa', 'nome'],
    },
    {
      title: t('Área de Atuação'),
      dataIndex: ['area', 'id'],
      render: text => getAreaCaminho(text)
    },
    {
      title: t('Prioridade'),
      dataIndex: 'prioridade',
      render: text => t(findById(Consulta.Prioridade, text)?.label)
    },
    {
      title: t('Tipo de Trabalho'),
      dataIndex: ['tipoTrabalho', 'descricao'],
    },
    {
      title: t('Assunto'),
      dataIndex: ['assunto', 'descricao'],
    },
    {
      title: t('Título'),
      dataIndex: 'titulo',
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      render: text => t(findById(Consulta.Status, text)?.label)
    },
    {
      title: t('Abertura'),
      dataIndex: 'dataAbertura',
      render: (text) => formatDate(text)
    },
  ];

  const onUnload = () => {
    dispatch(fetchConsultasPorStatus());
    dispatch(fetchConsultas());
  };

  const handleRowClick = consulta => {
    openPopupConsultivoConsulta(consulta, 'cadastro', { onUnload });
  };

  const title = () => {
    return (
      <Row gutter={[8, 8]} align="bottom" justify="end">
        <Col flex="1">
          <h1>{t('Consultas')}</h1>
        </Col>
        <Col>
          <MiniTable.DownloadButton disabled={loading || consultas.length === 0} miniTableRef={miniTableRef}/>
        </Col>
      </Row>);
  };

  return (
    <Spin spinning={loading}>
      <FlexCard bordered={false}>
        <div className={styles['consultas']}>
          <MiniTable
            ref={miniTableRef}
            columns={columns}
            dataSource={consultas}
            pagination={false}
            rowClassName={styles['consultas-row']}
            onRow={(record, rowIndex) => ({ onClick: () => handleRowClick(record) })}
            title={title}
          />
        </div>
      </FlexCard>
    </Spin>
  )
}

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dvxBpRzVUz5QU4fsDut4DQ\\=\\= .\\+RI61HQ6DWhIORCXmqE2vw\\=\\= {\n  width: 384px;\n}\n.dvxBpRzVUz5QU4fsDut4DQ\\=\\= td {\n  vertical-align: top;\n}\n.dvxBpRzVUz5QU4fsDut4DQ\\=\\= td + td {\n  padding-left: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/components/input-consultivo-area-tipo-trabalho-fases/_styles.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;AAAJ;AAFA;EAMI,mBAAA;AADJ;AALA;EAUI,kBAAA;AAFJ","sourcesContent":[".input-consultivo-area-tipo-trabalho-fases {\n  .descricao {\n    width: 384px;\n  }\n\n  td {\n    vertical-align: top;\n  }\n\n  td + td {\n    padding-left: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-consultivo-area-tipo-trabalho-fases": "dvxBpRzVUz5QU4fsDut4DQ==",
	"descricao": "+RI61HQ6DWhIORCXmqE2vw=="
};
export default ___CSS_LOADER_EXPORT___;

import { Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../components/action-bar';
import ActionButton from '../../../components/action-button';
import MiniTable from '../../../components/mini-table';
import useLoading from '../../../hooks/useLoading';
import { contasAlertasSelector, contasSearchSelector, contasSelector, fetchContas } from '../../../store/empresa';
import { openPopup } from '../../../util/window';
import Alertas from '../_Alertas';
import styles from '../_styles.less';
import EmpresaContasTable from './_table';
import useModulo from "../../../hooks/useModulo";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";

export default function EmpresaContasDetailPage() {
  const miniTableRef = useRef();
  const dispatch = useDispatchWithResult();
  const contas = useSelector(contasSelector);
  const search = useSelector(contasSearchSelector);
  const alertas = useSelector(contasAlertasSelector);
  const loading = useLoading(fetchContas.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const { moduloBasePath } = useModulo();
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSearch = search => {
    dispatch(fetchContas({ empresaId, search }));
  };

  const handleDetail = conta => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/contas/${conta.id}`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/contas/`, `logs_/empresas/${empresaId}/contas/`);
  };

  const actions = [];
  actions.push(<ActionButton.Logs noTitle onClick={handleLogs}/>);
  actions.push(<MiniTable.DownloadButton disabled={loading || contas.length === 0} miniTableRef={miniTableRef}/>);
  const title = () => <ActionBar search={search} onSearch={handleSearch}>{actions}</ActionBar>;
  return (
    <Space direction="vertical" className={styles['space']}>
      <EmpresaContasTable
        dataSource={contas}
        loading={loading}
        miniTableRef={miniTableRef}
        onClick={handleDetail}
        onSelectedIdsChange={setSelectedIds}
        selectedIds={selectedIds}
        title={title}
      />
      <Alertas alertas={alertas}/>
    </Space>);
}

EmpresaContasDetailPage.propTypes = {
  contas: PropTypes.array,
  onCreate: PropTypes.func,
  onRemove: PropTypes.func,
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { consultivoFiltroSelector } from "./consultivoFiltro";

const initialState = {
  consultasPorStatus: [],
};

export const consultasPorStatusSelector = state => state.consultivoCartoes.consultasPorStatus;

export const fetchConsultasPorStatus = createAsyncThunk(
  'consultivoCartoes/fetchConsultasPorStatus',
  async (_, thunkAPI) => {
    try {
      const filtro = consultivoFiltroSelector(thunkAPI.getState());
      const { data } = await axios.get('/consultivo/cartoes/consultas-por-status', { params: filtro });
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const slice = createSlice({
  name: 'consultivoCartoes',
  initialState,
  reducers: {
    resetar: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchConsultasPorStatus.fulfilled]: (state, action) => {
      state.consultasPorStatus = action.payload;
    },
  },
});

export default slice.reducer;

export const { resetar } = slice.actions;

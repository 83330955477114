import { configureStore } from '@reduxjs/toolkit';
import acessos from './acessos';
import autenticacao from './autenticacao';
import consultivo from './consultivo';
import consultivoFiltro from './consultivoFiltro';
import consultivoOperacional from './consultivoOperacional';
import consultivoCartoes from './consultivoCartoes';
import consultivoPesquisa from './consultivoPesquisa';
import societarioDashboard from './societarioDashboard';
import regulatorioDashboard from './regulatorioDashboard';
import complianceDashboard from './complianceDashboard';
import drive from './drive';
import empresa from './empresa';
import empresas from './empresas';
import i18n from './i18n';
import layout from './layout';
import loading from './loading';
import societarioTabela from './societarioTabela';
import regulatorioTabela from './regulatorioTabela';
import complianceTabela from './complianceTabela';
import workflows from './workflows';
import workflow from './workflow';
import consultivoArea from "./consultivoArea";
import consultivoConsulta from "./consultivoConsulta";
import consultivoDashboard from "./consultivoDashboard";

export function createStore(isDev) {
  return configureStore({
    reducer: {
      loading,
      autenticacao,
      i18n,
      acessos,
      empresas,
      empresa,
      societarioDashboard,
      societarioTabela,
      regulatorioDashboard,
      regulatorioTabela,
      complianceDashboard,
      complianceTabela,
      consultivo,
      consultivoArea,
      consultivoConsulta,
      consultivoFiltro,
      consultivoDashboard,
      consultivoPesquisa,
      consultivoCartoes,
      consultivoOperacional,
      drive,
      layout,
      workflows,
      workflow,
    },
    devTools: isDev,
  });
}

export function unwrapResult(actionResult) {
  if (actionResult?.error) {
    if (actionResult?.payload)
      return Promise.reject(actionResult.payload);
    return Promise.reject(actionResult.error);
  }
  return Promise.resolve(actionResult?.payload);
}

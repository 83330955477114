import { CheckOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, FilePdfOutlined, FilterFilled, FilterOutlined, HistoryOutlined, PlusOutlined, QuestionCircleTwoTone, UploadOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

function ActionButtonCreate({ to, ...props }) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = () => history.push(to);
  return (
    <ActionButton
      ghost
      type="primary"
      onClick={handleClick}
      title={t('Criar')}
      icon={<PlusOutlined/>}
      {...props}
    />
  );
}

ActionButtonCreate.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

function ActionButtonCreateCopy(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Criar Cópia')}
      icon={<PlusOutlined/>}
      {...props}
    />
  );
}

ActionButtonCreateCopy.propTypes = {
  onClick: PropTypes.func,
};


function ActionButtonEdit({ to, ...props }) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = () => history.push(to);
  return (
    <ActionButton
      ghost
      type="primary"
      onClick={handleClick}
      title={t('Alterar')}
      icon={<EditOutlined/>}
      {...props}
    />
  );
}

ActionButtonEdit.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
};

function ActionButtonRemove({ onClick, ...props }) {
  const { t } = useTranslation();

  const handleConfirm = () => {
    if (typeof onClick === 'function') {
      const result = onClick();
      if (result instanceof Promise)
        result.catch(err => message.error(err.mensagem));
    }
  };

  return (
    <Popconfirm
      title={t('Você confirma a exclusão do(s) registro(s) selecionado(s)?')}
      onConfirm={handleConfirm}
      okText={t('Sim, excluir')}
      cancelText={t('Não excluir')}
      okButtonProps={{ type: 'danger' }}
      icon={<QuestionCircleTwoTone twoToneColor="#ff4d4f"/>}
      zIndex={10000}
    >
      <ActionButton
        ghost
        type="danger"
        title={t('Excluir')}
        icon={<DeleteOutlined/>}
        {...props}
      />
    </Popconfirm>
  );
}

ActionButtonRemove.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionButtonSave(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      type="primary"
      title={t('Salvar')}
      icon={<CheckOutlined/>}
      {...props}
    />
  );
}

ActionButtonSave.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionButtonLogs(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Logs')}
      icon={<HistoryOutlined/>}
      {...props}
    />
  );
}

ActionButtonLogs.propTypes = {
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionButtonBack(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Voltar')}
      {...props}
    />
  );
}

ActionButtonBack.propTypes = {
  onClick: PropTypes.func,
};

function ActionButtonClose(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Fechar')}
      {...props}
    />
  );
}

ActionButtonClose.propTypes = {
  onClick: PropTypes.func,
};

function ActionButtonCancel(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Cancelar')}
      {...props}
    />
  );
}

ActionButtonCancel.propTypes = {
  onClick: PropTypes.func,
};

function ActionButtonFilter({ filtered, ...props }) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Filtro')}
      icon={filtered ? <FilterFilled/> : <FilterOutlined/>}
      {...props}
    />
  );
}

ActionButtonFilter.propTypes = {
  filtered: PropTypes.bool,
  onClick: PropTypes.func,
};

function ActionButtonUpload(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Enviar Arquivo')}
      icon={<UploadOutlined/>}
      {...props}
    />
  );
}

ActionButtonUpload.propTypes = {
  onClick: PropTypes.func,
};

function ActionButtonDownload(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Baixar Arquivo')}
      icon={<DownloadOutlined/>}
      {...props}
    />
  );
}

ActionButtonDownload.propTypes = {
  onClick: PropTypes.func,
};

function ActionButtonGeneratePdf(props) {
  const { t } = useTranslation();
  return (
    <ActionButton
      title={t('Gerar PDF')}
      icon={<FilePdfOutlined/>}
      {...props}
    />
  );
}

ActionButtonGeneratePdf.propTypes = {
  onClick: PropTypes.func,
};

function ActionButton({ disabled, loading, noTitle, onClick, primary, title, ...props }) {
  const type = primary ? 'primary' : undefined;
  const handleClick = e => {
    if (typeof onClick === 'function') {
      e.preventDefault();
      onClick();
    }
  };

  const button = (
    <Button
      disabled={disabled}
      loading={loading}
      onClick={handleClick}
      type={type}
      children={noTitle ? undefined : title}
      {...props}
    />
  );

  if (noTitle)
    return (
      <Tooltip title={title}>
        {button}
      </Tooltip>);

  return button;
}

ActionButton.defaultProps = {
  disabled: false,
  loading: false,
  primary: false,
};

ActionButton.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  title: PropTypes.string,
};

ActionButton.Create = ActionButtonCreate;
ActionButton.CreateCopy = ActionButtonCreateCopy;
ActionButton.Edit = ActionButtonEdit;
ActionButton.Remove = ActionButtonRemove;
ActionButton.Save = ActionButtonSave;
ActionButton.Logs = ActionButtonLogs;
ActionButton.Back = ActionButtonBack;
ActionButton.Close = ActionButtonClose;
ActionButton.Cancel = ActionButtonCancel;
ActionButton.Filter = ActionButtonFilter;
ActionButton.Upload = ActionButtonUpload;
ActionButton.Download = ActionButtonDownload;
ActionButton.GeneratePdf = ActionButtonGeneratePdf;

export default ActionButton;

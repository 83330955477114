import React from 'react';
import styles from './_ConsultaCollectionItem.less';
import { AlertOutlined, CheckSquareOutlined, ClockCircleOutlined, EyeInvisibleOutlined, PaperClipOutlined, UserOutlined } from "@ant-design/icons";
import { Consulta, findById } from "../../../constants";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useFormatter from "../../../hooks/useFormatter";
import { openPopupConsultivoConsulta } from "../../../util/window";
import { fetchConsultasPorStatus } from "../../../store/consultivoCartoes";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import useConsultivo from "../../../hooks/useConsultivo";

export default function ConsultaCollectionItem({ consulta }) {
  const { t } = useTranslation();
  const { formatDate } = useFormatter();
  const { getAreaCaminho } = useConsultivo();
  const dispatch = useDispatchWithResult();

  const areaBorda = !!consulta.area?.cor ? '#' + consulta.area?.cor : 'rgba(0,0,0,0.2)';
  const areaCor = !!consulta.area?.cor ? '#' + undefined : 'rgba(0,0,0,0.2)';
  const areaNome = consulta.area?.id ? getAreaCaminho(consulta.area?.id) : <i>{t('(sem área)')}</i>;
  const assuntoCor = !!consulta.assunto?.cor ? '#' + consulta.assunto?.cor : 'rgba(0,0,0,0.2)';
  const assuntoDescricao = consulta.assunto?.descricao ?? <i>{t('(sem assunto)')}</i>;
  const tipoTrabalhoDescricao = consulta.tipoTrabalho?.descricao ?? <i style={{ color: 'rgba(0,0,0,0.2)' }}>{t('(sem tipo de trabalho)')}</i>;
  const fasesConcluidas = consulta.fases.filter(fase => fase.dataConclusao).length;

  const onUnload = () => {
    dispatch(fetchConsultasPorStatus());
  }

  const handleClick = e => {
    e.preventDefault();
    openPopupConsultivoConsulta(consulta, 'cadastro', { onUnload });
  }

  return (
    <div
      className={styles.ConsultaCollectionItem}
      onClick={handleClick}
      style={{ borderColor: areaBorda }}
    >
      <div className={styles.header} style={{ color: areaCor }}>
        <strong>{areaNome}</strong>
      </div>
      <div className={styles.header}>
        <div
          className={styles.assunto}
          style={{ backgroundColor: assuntoCor }}
        >
          {assuntoDescricao}
        </div>
        <div className={styles.tipoTrabalho}>
          {tipoTrabalhoDescricao}
        </div>
      </div>
      <div>
        <div>{consulta.titulo}</div>
      </div>
      <div className={styles.infoLeftCollection}>
        <div className={styles.info}>
          <div>
            <AlertOutlined/>
          </div>
          <div>
            {t(findById(Consulta.Prioridade, consulta.prioridade)?.label)}
          </div>
        </div>
        <div className={styles.info}>
          <div>
            <ClockCircleOutlined/>
          </div>
          <div>
            {formatDate(consulta.dataPrevista)}
          </div>
        </div>
        {consulta.sigilo &&
          <div className={styles.info}>
            <div>
              <EyeInvisibleOutlined/>
            </div>
          </div>
        }
        {consulta.anexos.length > 0 &&
          <div className={styles.info}>
            <div>
              <PaperClipOutlined/>
            </div>
            <div>
              {consulta.anexos.length}
            </div>
          </div>
        }
        {consulta.fases.length > 0 &&
          <div className={styles.info}>
            <div>
              <CheckSquareOutlined/>
            </div>
            <div>
              {fasesConcluidas}/{consulta.fases.length}
            </div>
          </div>
        }
      </div>
      <div className={styles.infoRightCollection}>
        <div className={styles.info}>
          <div>
            <UserOutlined/>
          </div>
          <div>
            {consulta.usuarioCriador.nome}
          </div>
        </div>
      </div>
    </div>
  );
}

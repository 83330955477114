import React, { useRef } from 'react';
import { Spin } from "antd";
import styles from "./_styles.less";
import { useTranslation } from "react-i18next";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import useLoading from "../../../hooks/useLoading";
import { consultasPorStatusSelector, fetchConsultasPorStatus } from "../../../store/consultivoDashboard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { consultivoFiltroResetar } from "../../../store/consultivoFiltro";
import { Consulta } from "../../../constants";


export default function ConsultasPorStatusGraficoCard() {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const loading = useLoading(fetchConsultasPorStatus.typePrefix);
  const { total, emAndamento, finalizadas, } = useSelector(consultasPorStatusSelector);

  const data = {
    labels: [
      'Encerrada',
      'Em Andamento',
    ],
    datasets: [
      {
        label: t('Consultas'),
        data: [finalizadas, emAndamento],
        backgroundColor: ['#009edb', '#006389']
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        top: 32,
        left: 32,
        bottom: 32,
      }
    },
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        font: {
          size: 14,
        },
        offset: -8,
        formatter(value) {
          return value + '\n(' + Math.round((100 * value / total)) + '%)'
        },
        textAlign: 'center'
      },
      legend: {
        position: 'right',
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          usePointStyle: true,
          padding: 24,
        },
      }
    },
  }

  const handleClick = e => {
    const { current: chart } = chartRef;
    if (chart) {
      const element = getElementAtEvent(chart, e);
      if (element.length) {
        switch (element[0].index) {
          case 0:
            dispatch(consultivoFiltroResetar());
            history.push('/consultivo/pesquisa', { status: Consulta.Status.Concluido.id });
            break;
          case 1:
            dispatch(consultivoFiltroResetar());
            history.push('/consultivo/operacional');
            break;
        }
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <div className={styles['chart-card']}>
        <div className={styles['title']}>Casos por Status</div>
        <div className={styles['content']}>
          <Doughnut
            ref={chartRef}
            type="bar"
            data={data}
            plugins={[ChartDataLabels]}
            options={options}
            onClick={handleClick}
          />
        </div>
      </div>
    </Spin>
  )
}

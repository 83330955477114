import React from 'react';
import { fetchConsultas, previewConsulta, previewSelector } from "../../../store/consultivoPesquisa";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { openPopupConsultivoConsulta } from "../../../util/window";
import { useSelector } from "react-redux";
import { Col, Row, Spin } from "antd";
import TextItem from "../../../components/text-item";
import useLoading from "../../../hooks/useLoading";
import { useTranslation } from "react-i18next";
import ActionBar from "../../../components/action-bar";
import styles from "./_styles.less";
import ActionButton from "../../../components/action-button";
import useFormatter from "../../../hooks/useFormatter";
import useConsultivo from "../../../hooks/useConsultivo";
import TextAnexo from "../../../components/text-anexo";

export default function PreviewPanel() {
  const { t } = useTranslation()
  const { getAreaCaminho } = useConsultivo();
  const { formatDate } = useFormatter();
  const dispatch = useDispatchWithResult();
  const consulta = useSelector(previewSelector);
  const loading = useLoading(previewConsulta.typePrefix);

  const onUnload = () => {
    dispatch(fetchConsultas());
    dispatch(previewConsulta(consulta));
  };

  const handleClick = () => {
    openPopupConsultivoConsulta(consulta, 'cadastro', { onUnload });
  };

  return (
    <div className={styles.PreviewPanel}>
      <div className={styles.cabecalho}>
        <ActionBar>
          <ActionButton type="primary" onClick={handleClick} disabled={loading}>
            Abrir Consulta
          </ActionButton>
        </ActionBar>
      </div>
      <div className={styles.conteudo}>
        <Spin spinning={loading}>
          <TextItem label={t('Título')}>{consulta?.titulo}</TextItem>
          <TextItem label={t('Descrição')}>{consulta?.descricao}</TextItem>
          <TextItem label={t('Resposta')}>{consulta?.resposta}</TextItem>
          <TextItem label={t('Tags')}>{consulta?.tags?.join(', ')}</TextItem>
          <TextItem label={t('Área de Atuação')}>{getAreaCaminho(consulta?.area?.id)}</TextItem>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <TextItem label={t('Tipo de Trabalho')}>{consulta?.tipoTrabalho?.descricao}</TextItem>
            </Col>
            <Col span={12}>
              <TextItem label={t('Assunto')}>{consulta?.assunto?.descricao}</TextItem>
            </Col>
          </Row>
          <TextItem label={t('Acesso')}>{consulta?.empresa?.acesso?.nome}</TextItem>
          <TextItem label={t('Empresa')}>{consulta?.empresa?.nome}</TextItem>
          <TextItem label={t('Unidade')}>{consulta?.unidade?.apelido}</TextItem>
          <Row gutter={[16, 8]}>
            <Col span={12}>
              <TextItem label={t('Abertura em')}>{formatDate(consulta?.dataAbertura)}</TextItem>
            </Col>
            <Col span={12}>
              <TextItem label={t('Conclusão em')}>{formatDate(consulta?.dataConclusao)}</TextItem>
            </Col>
          </Row>
          <TextItem label={t('Responsável')}>{consulta?.usuarioResponsavel?.nome}</TextItem>
        </Spin>
      </div>
      <div className={styles.rodape}>
        <Spin spinning={loading}>
          {consulta?.anexos?.length > 0 &&(
            <label>
              {t('Anexos')}
              {consulta?.anexos?.map(anexo => (
                <TextAnexo key={anexo.uid} value={anexo.anexo}/>
              ))}
            </label>
          )}
        </Spin>
      </div>
    </div>
  )
}


import React, { useRef } from 'react';
import { Spin } from "antd";
import styles from './_styles.less';
import { Chart, getElementAtEvent } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import useLoading from "../../../hooks/useLoading";
import { consultasPorAreaSelector, fetchConsultasPorArea } from "../../../store/consultivoDashboard";
import { useDispatch, useSelector } from "react-redux";
import useConsultivo from "../../../hooks/useConsultivo";
import { useHistory } from "react-router";
import { consultivoFiltroAplicar, consultivoFiltroResetar } from "../../../store/consultivoFiltro";

export default function ConsultasPorAreaGraficoCard() {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const loading = useLoading(fetchConsultasPorArea.typePrefix);
  const areas = useSelector(consultasPorAreaSelector);
  const { getAreaCaminho } = useConsultivo();

  const labels = [];
  const datasetData = [];

  areas.forEach(({ areaId, quantidade }) => {
    labels.push(getAreaCaminho(areaId));
    datasetData.push(quantidade);
  })

  const data = {
    labels,
    datasets: [
      {
        label: t('Consultas'),
        data: datasetData,
        backgroundColor: '#006389'
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        top: 32,
      }
    },
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        color: '#006389',
        font: {
          size: 14,
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      xAxes: {
        ticks: {
          font: {
            size: 9,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        }
      },
      yAxes: {
        ticks: {
          maxTicksLimit: 4,
          padding: 8,
        },
        grid: {
          borderDash: [2, 2],
          borderDashOffset: 0,
          drawBorder: false,
          drawTicks: false,
          lineWidth: 2
        }
      },
    },
  }

  const handleClick = e => {
    const { current: chart } = chartRef;
    if (chart) {
      const element = getElementAtEvent(chart, e);
      if (element.length) {
        const area = areas[element[0].index]
        dispatch(consultivoFiltroResetar());
        dispatch(consultivoFiltroAplicar({ areaId: area.areaId }));
        history.push('/consultivo/cartoes');
      }
    }
  }

  return (
    <Spin spinning={loading}>
      <div className={styles['chart-card']}>
        <div className={styles['content']}>
          <Chart
            ref={chartRef}
            type="bar"
            data={data}
            plugins={[ChartDataLabels]}
            options={options}
            onClick={handleClick}
          />
        </div>
        <div className={styles['title']}>
          Área
        </div>
      </div>
    </Spin>
  )
}

import { DownOutlined } from '@ant-design/icons';
import { Divider, Layout, Menu, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Brand from '../../components/brand';
import Flag from '../../components/flag';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import { ambienteIdSelector, ambienteIdsSelector, ambienteNomeSelector, isAutenticadoSelector, isDiretorSelector, isSuperUsuarioSelector, nomeSelector, sair, usuarioIdSelector } from '../../store/autenticacao';
import { isMDSelector, isXSSelector } from '../../store/layout';
import AlterarSenhaDrawer from './_AlterarSenhaDrawer';
import SmtpDrawer from './_SmtpDrawer';
import styles from './_styles.less';
import axios from "axios";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import Logo from "../../components/logo";
import useModulo from "../../hooks/useModulo";
import { openPopup } from "../../util/window";

export default function AuthenticatedLayout({ children, modulo, acessoModulo, menu, contentStyle }) {
  const { t, i18n } = useTranslation();
  const { aurthorizeModulo, authorizeAcessoModulo, hasModulo } = useModulo();
  const isXS = useSelector(isXSSelector);
  const isMD = useSelector(isMDSelector);
  const isAutenticado = useSelector(isAutenticadoSelector);
  const usuarioId = useSelector(usuarioIdSelector);
  const isDiretor = useSelector(isDiretorSelector);
  const isSuperUsuario = useSelector(isSuperUsuarioSelector);
  const ambienteIds = useSelector(ambienteIdsSelector);
  const nome = useSelector(nomeSelector);
  const ambienteId = useSelector(ambienteIdSelector);
  const ambienteNome = useSelector(ambienteNomeSelector);
  const history = useHistory();
  const dispatch = useDispatchWithResult();
  const [alterarSenhaVisible, setAlterarSenhaVisible] = useState(false);
  const [smtpVisible, setSmtpVisible] = useState(false);
  const [ambientes, setAmbientes] = useState([]);

  if (modulo)
    aurthorizeModulo(modulo);

  if (acessoModulo)
    authorizeAcessoModulo(acessoModulo);

  useEffect(() => {
    if (!isAutenticado)
      history.push('/autenticar');
  }, [isAutenticado]);

  useEffect(() => {
    if (ambienteIds.length > 0 || isSuperUsuario)
      unwrapAxiosPromise(axios.get('/ambientes'))
        .then(data => {
          setAmbientes(data);
        })
    else
      setAmbientes([]);
  }, [ambienteIds, isSuperUsuario]);

  const handleLogoClick = e => {
    e.preventDefault();
    history.push('/');
  };

  const handleMenuClick = ({ key }) => {
    history.push(key);
  };

  const handleAmbienteClick = ({ key }) => {
    unwrapAxiosPromise(axios.post(`/usuarios/${usuarioId}/alterar-ambiente`, { ambienteId: Number(key) }))
      .then(() => location.pathname = '/');
  }

  const handleLogsClick = () => {
    openPopup(`/logs?categoria=/`, `logs_/`);
  };

  const handleLanguageClick = ({ key }) => {
    i18n.changeLanguage(key);
  };

  const handleAlterarSenhaClick = () => {
    setAlterarSenhaVisible(true);
  };

  const handleSmtpClick = () => {
    setSmtpVisible(true);
  };

  const handleSairClick = () => {
    dispatch(sair())
      .finally(() => {
        message.info(t('Você saiu'));
        history.push('/autenticar');
      });
  };

  return (
    <Layout className={styles['authenticated-layout']}>
      <Layout.Header theme="dark" className={styles['header']}>
        <div className={styles['brand']} onClick={handleLogoClick}>
          <Brand height="24px" theme="dark" iconOnly={!isMD}/>
        </div>
        <Divider type="vertical" className={styles['divider']}/>
        <div className={styles['ambiente']}>
          <Logo src={"/api/ambientes/" + ambienteId + "/logo.png"} width={24} height={24}/>
          {!isXS && <span>{ambienteNome}</span>}
        </div>
        <Divider type="vertical" className={styles['divider']}/>
        <div className={styles['menu']}>
          {menu}
        </div>
        <Divider type="vertical" className={styles['divider']}/>
        <Menu theme="dark" mode="horizontal" disabledOverflow>
          <Menu.SubMenu title={isXS ? undefined : nome} icon={<DownOutlined/>}>
            {isXS && <Menu.Item key="nome" disabled>{nome}</Menu.Item>}
            <Menu.Divider/>
            <Menu.Item key="/" onClick={handleMenuClick}>{t('Home')}</Menu.Item>
            <Menu.Item key="/change-password" onClick={handleAlterarSenhaClick}>{t('Alterar Senha')}</Menu.Item>
            {(ambientes.length > 0 || isSuperUsuario) && (
              <Menu.SubMenu title={t('Alterar Ambiente')}>
                {ambientes.map(ambiente =>
                  <Menu.Item key={ambiente.id} onClick={handleAmbienteClick}>
                    <div className={styles['ambiente-menu-item']}>
                      <Logo src={ambiente.logo?.url} width={24} height={24}/>
                      <span>{ambiente.nome}</span>
                    </div>
                  </Menu.Item>)}
              </Menu.SubMenu>
            )}
            <Menu.SubMenu
              icon={<Flag countryCode={i18n.language.substr(-2)}/>}
              title={' ' + i18n.language.substr(-2)}
            >
              <Menu.Item key="pt-BR" onClick={handleLanguageClick}><Flag countryCode="BR"/> BR</Menu.Item>
              <Menu.Item key="en-US" onClick={handleLanguageClick}><Flag countryCode="US"/> US</Menu.Item>
            </Menu.SubMenu>
            <Menu.Divider/>
            {isSuperUsuario && <Menu.Item key="/ambientes" onClick={handleMenuClick}>{t('Ambientes')}</Menu.Item>}
            {isDiretor && <Menu.Item key="/usuarios" onClick={handleMenuClick}>{t('Usuários')}</Menu.Item>}
            {isDiretor && <Menu.Item key="/logs" onClick={handleLogsClick}>{t('Logs')}</Menu.Item>}
            {hasModulo('consultivo') && isDiretor && <Menu.Item key="/consultivo/areas" onClick={handleMenuClick}>{t('Áreas de Atuação')}</Menu.Item>}
            {isSuperUsuario && <Menu.Item key="/smtp" onClick={handleSmtpClick}>{t('SMTP')}</Menu.Item>}
            {isSuperUsuario && <Menu.Item key="/emails" onClick={handleMenuClick}>{t('Histórico de E-mails')}</Menu.Item>}
            {isDiretor && <Menu.Divider/>}
            <Menu.Item key="/quit" onClick={handleSairClick}>{t('Sair')}</Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </Layout.Header>
      <Layout.Content className={styles['content-wrapper']}>
        <div className={styles['content']} style={contentStyle}>
          {children}
        </div>
      </Layout.Content>
      <AlterarSenhaDrawer visible={alterarSenhaVisible} onClose={() => setAlterarSenhaVisible(false)}/>
      <SmtpDrawer visible={smtpVisible} onClose={() => setSmtpVisible(false)}/>
    </Layout>
  );
}

AuthenticatedLayout.propTypes = {
  children: PropTypes.node,
  menu: PropTypes.node,
  contentStyle: PropTypes.object
};

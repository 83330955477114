import { Checkbox, Form, message } from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { valuesToFormData } from '../../util/formData';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import ActionButton from '../action-button';
import InputName from '../input-name';
import MiniFormDrawer from '../mini-form-drawer';
import styles from './_styles.less';
import { EmpresaTabs, findByIdAndMap, Modulos } from "../../constants";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { fetchAcessos } from "../../store/acessos";
import InputNumber from "../input-number";
import { openPopup } from "../../util/window";

const defaultValue = {
  nome: '',
  organograma: '',
  funcoes: EmpresaTabs.map(tab => tab.name)
};

export default function AcessoDrawer({ loading, onAfterClose, onChange, onClose, value = defaultValue, visible }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loadingSave, setLoadingSave] = useState(false);
  const modulos = Form.useWatch('modulos', form) ?? value?.modulos ?? [];
  const funcoes = Form.useWatch('funcoes', form) ?? value?.funcoes;
  const hasCadastro = funcoes && funcoes.includes('cadastro');
  const dispatch = useDispatchWithResult();

  const fireChange = data => typeof onChange === 'function' ? onChange(data) : undefined;
  const fireClose = () => typeof onClose === 'function' ? onClose() : undefined;

  const modulosOptions = Modulos.map(tab => ({ label: t(tab.label), value: tab.id }))
  const funcoesOptions = EmpresaTabs.map(tab => {
    let prefix = 'Geral';
    if (tab.modulos.length === 1)
      prefix = findByIdAndMap(Modulos, tab.modulos[0], m => m.label)
    let disabled = true;
    for (const modulo of tab.modulos)
      if (modulos.includes(modulo)) {
        disabled = false;
        break;
      }
    if (tab.name !== 'cadastro' && !hasCadastro)
      disabled = true;

    return ({
      label: t(prefix) + ': ' + t(tab.label),
      value: tab.name,
      disabled
    });
  });

  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);

  const handleDrawerAfterOpen = () => {
    document.querySelector('#nome').focus();
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/&acessoId=${value.id}`, `logs_/acessos/${value.id}/`);
  };

  const handleFinish = values => {
    setLoadingSave(true);
    const formData = valuesToFormData(values);
    const promise = value.id ?
      axios.put(`/acessos/${value.id}`, formData) :
      axios.post('/acessos', formData);

    unwrapAxiosPromise(promise, form)
      .then(data => {
        message.success(t(value.id ?
          'Acesso alterado com sucesso' :
          'Acesso incluído com sucesso'));
        fireChange({ ...value, ...data });
        fireClose();
        dispatch(fetchAcessos());
      })
      .finally(() => setLoadingSave(false));
  };

  const footer = (
    <>
      <ActionButton.Save
        disabled={loading}
        loading={loadingSave}
        onClick={() => form.submit()}
      />
      <ActionButton.Close
        disabled={loadingSave}
        onClick={fireClose}
      />
      {value.id > 0 && <ActionButton.Logs onClick={handleLogs}/>}
    </>
  );

  const drawerTitle = value.id ? (value.id + ' - ' + value.nome) : t('Criar Acesso');

  return (
    <MiniFormDrawer
      drawerTitle={drawerTitle}
      drawerFooter={footer}
      drawerWidth={500}
      drawerVisible={visible}
      form={form}
      loading={loading}
      onDrawerClose={fireClose}
      onDrawerAfterOpen={handleDrawerAfterOpen}
      onDrawerAfterClose={onAfterClose}
      onFinish={handleFinish}
    >
      <Form.Item name="nome" label={t('Nome')}>
        <InputName disabled={loadingSave}/>
      </Form.Item>
      <Form.Item name="numeroEmpresas" label={t('Número de Empresas')}>
        <InputNumber disabled={loadingSave} maximumFractionDigits={0}/>
      </Form.Item>
      <Form.Item name="modulos" label={t('Módulos')}>
        <Checkbox.Group className={styles['checkbox-vertical']} disabled={loadingSave} options={modulosOptions}/>
      </Form.Item>
      <Form.Item name="funcoes" label={t('Funções')}>
        <Checkbox.Group className={styles['checkbox-vertical']} disabled={loadingSave} options={funcoesOptions}/>
      </Form.Item>
    </MiniFormDrawer>
  );
}

AcessoDrawer.propTypes = {
  loading: PropTypes.bool,
  onAfterClose: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  value: PropTypes.object,
  visible: PropTypes.bool,
};

import { Form, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../../components/action-bar';
import ActionButton from '../../../../../components/action-button';
import useDispatchWithResult from '../../../../../hooks/useDispatchWithResult';
import useLoading from '../../../../../hooks/useLoading';
import { fetchTiposTrabalho, fetchTipoTrabalho, tipoTrabalhoSelector } from '../../../../../store/consultivoArea';
import unwrapAxiosPromise from '../../../../../util/unwrapAxiosPromise';
import ConsultivoAreaTipoTrabalhoForm from './_form';

export default function ConsultivoAreaTipoTrabalhoEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const tipoTrabalho = useSelector(tipoTrabalhoSelector);
  const loading = useLoading(fetchTipoTrabalho.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;
  const id = match.params.id;

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(tipoTrabalho);
    setImmediate(() => document.querySelector('#descricao').focus());
  }, [tipoTrabalho]);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/consultivo/areas/${areaId}/tipos-trabalho/${id}`, values), form)
      .then(() => {
        message.success(t('Registro alterado com sucesso'));
        history.replace(`/consultivo/areas/${areaId}/tipos-trabalho/${id}`);
        dispatch(fetchTiposTrabalho({ areaId }));
        dispatch(fetchTipoTrabalho({ areaId, id }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`/consultivo/areas/${areaId}/tipos-trabalho/${id}`);
  };

  const title = (
    <ActionBar>
      <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
      <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
    </ActionBar>);

  return (
    <ConsultivoAreaTipoTrabalhoForm
      autoFillByCnpj="normal"
      disabled={loadingSave}
      form={form}
      loading={loading}
      onFinish={handleFinish}
      title={title}
    />);
}

import React, { useRef } from 'react';
import { Spin } from "antd";
import styles from "./_styles.less";
import { useTranslation } from "react-i18next";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import useLoading from "../../../hooks/useLoading";
import { consultasPorMesSelector, fetchConsultasPorMes } from "../../../store/consultivoDashboard";
import { useDispatch, useSelector } from "react-redux";
import useFormatter from "../../../hooks/useFormatter";
import moment from "moment";
import { useHistory } from "react-router";
import { consultivoFiltroResetar } from "../../../store/consultivoFiltro";


export default function ConsultasPorMesGraficoCard() {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const loading = useLoading(fetchConsultasPorMes.typePrefix);
  const meses = useSelector(consultasPorMesSelector);
  const { formatMonth } = useFormatter();

  const labels = [];
  const datasetData = [];

  meses.forEach(({ data, quantidade }) => {
    labels.push(formatMonth(moment.utc(data).month() + 1));
    datasetData.push(quantidade);
  })

  const data = {
    labels,
    datasets: [
      {
        label: t('Consultas'),
        data: datasetData,
        backgroundColor: '#006389'
      }
    ]
  }

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      padding: {
        top: 32,
      }
    },
    plugins: {
      datalabels: {
        align: 'end',
        anchor: 'end',
        color: '#006389',
        font: {
          size: 14,
        }
      },
      legend: {
        display: false
      }
    },
    scales: {
      xAxes: {
        ticks: {
          font: {
            size: 9,
          },
        },
        grid: {
          display: false,
          drawBorder: false,
        }
      },
      yAxes: {
        ticks: {
          display: false,
          maxTicksLimit: 4,
          padding: 8,
        },
        grid: {
          borderDash: [2, 2],
          borderDashOffset: 0,
          drawBorder: false,
          drawTicks: false,
          lineWidth: 2
        }
      },
    },
  }

  const handleClick = e => {
    const { current: chart } = chartRef;
    if (chart) {
      const element = getElementAtEvent(chart, e);
      if (element.length) {
        const mes = meses[element[0].index]
        const createdAtDe = moment.utc(mes.data).subtract(3, 'hours').format('YYYY-MM-DD');
        const createdAtAte = moment.utc(mes.data).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD');
        dispatch(consultivoFiltroResetar());
        history.push('/consultivo/pesquisa', { createdAtDe, createdAtAte });
      }
    }
  }


  return (
    <Spin spinning={loading}>
      <div className={styles['chart-card']}>
        <div className={styles['content']}>
          <Chart
            ref={chartRef}
            type="bar"
            data={data}
            plugins={[ChartDataLabels]}
            options={options}
            onClick={handleClick}
          />
        </div>
        <div className={styles['title']}>
          Mês
        </div>
      </div>
    </Spin>
  )
}

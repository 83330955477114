import React from 'react';
import { useSelector } from "react-redux";
import { consultasPorStatusSelector } from "../../../store/consultivoCartoes";
import styles from './_ConsultasPorStatusCollection.less';
import TipoTrabalhoFaseCollectionItem from "./_ConsultasPorStatusCollectionItem";

export default function ConsultasPorStatusCollection() {
  const consultasPorStatus = useSelector(consultasPorStatusSelector);
  return (
    <div className={styles.ConsultasPorStatusCollection}>
      {consultasPorStatus.map(({ status, consultas }) => <TipoTrabalhoFaseCollectionItem key={status} status={status} consultas={consultas}/>)}
    </div>
  )
}

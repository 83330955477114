import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import StatusCard from "./_StatusCard";
import styles from "./_styles.less";
import Logo from "../../../components/logo";
import { useDispatch, useSelector } from "react-redux";
import { ambienteIdSelector, ambienteNomeSelector } from "../../../store/autenticacao";
import useLoading from "../../../hooks/useLoading";
import { consultasPorStatusSelector, fetchConsultasPorStatus } from "../../../store/consultivoDashboard";
import { useHistory } from "react-router";
import { consultivoFiltroResetar } from "../../../store/consultivoFiltro";
import { Consulta } from "../../../constants";

export default function ConsultasPorStatusCardGroup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const ambienteId = useSelector(ambienteIdSelector);
  const ambienteNome = useSelector(ambienteNomeSelector);
  const loading = useLoading(fetchConsultasPorStatus.typePrefix);
  const { total, emAndamento, finalizadas, } = useSelector(consultasPorStatusSelector);

  return (
    <Row
      gutter={[0, 0]}
      className={styles['status-card-group']}
      justify="center"
    >
      <Col span={24} md={6}>
        <Spin spinning={loading}>
          <StatusCard
            count={total}
            loading={loading}
            title={t('Total de Consultas')}
          />
        </Spin>
      </Col>
      <Col span={24} md={6}>
        <Spin spinning={loading}>
          <StatusCard
            count={emAndamento}
            loading={loading}
            title={t('Em Andamento')}
            onClick={() => {
              dispatch(consultivoFiltroResetar());
              history.push('/consultivo/operacional');
            }}
          />
        </Spin>
      </Col>
      <Col span={24} md={6}>
        <Spin spinning={loading}>
          <StatusCard
            count={finalizadas}
            loading={loading}
            title={t('Finalizadas')}
            onClick={() => {
              dispatch(consultivoFiltroResetar());
              history.push('/consultivo/pesquisa', { status: Consulta.Status.Concluido.id });
            }}
          />
        </Spin>
      </Col>
      <Col span={24} md={6}>
        <div className={styles['ambiente-card']}>
          <Logo src={`/api/ambientes/${ambienteId}/logo.png`} width={24} height={24}/>
          <span>{ambienteNome}</span>
        </div>
      </Col>
    </Row>);
}

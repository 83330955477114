import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionBar from '../../components/action-bar';
import FlexCard from '../../components/flex-card';
import MiniTable from '../../components/mini-table';
import useQuery from '../../hooks/useQuery';
import AuthenticatedPopupLayout from '../../layouts/authenticated-popup';
import { formatCnpj } from '../../util/cnpj';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import LogsTable from './_table';

export default function LogsPage() {
  const miniTableRef = useRef();
  const {t} = useTranslation();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [activeSearch, setActiveSearch] = useState();
  const [dataSource, setDataSource] = useState([]);
  const query = useQuery();
  const categoria = query.get('categoria');
  const acessoId = query.get('acessoId');

  const showAcesso = categoria === '/' && !(acessoId > 0);
  const showEntidade = categoria === '/';

  const handleSearch = search => {
    if (!categoria) {
      setLoadingSearch(false);
      setActiveSearch(search);
      setDataSource([]);
      return;
    }

    setLoadingSearch(true);
    unwrapAxiosPromise(axios.get('/logs', { params: { search, categoria, acessoId } }))
        .then(data => {
          setActiveSearch(search);
          setDataSource(data);
          if (data.length && data[0].empresa) {
            document.title = 'Legal Panel - Logs - ' + (data[0].empresa.apelido || data[0].empresa.razaoSocial) + ' (' + formatCnpj(data[0].empresa.cnpj) + ')';
          } else {
            document.title = 'Legal Panel - Logs';
          }
        })
        .finally(() => setLoadingSearch(false));
  };

  const title = () => {
    return (
        <ActionBar onSearch={handleSearch}>
          <MiniTable.DownloadButton disabled={loadingSearch || dataSource.length === 0} miniTableRef={miniTableRef} />
        </ActionBar>);
  };

  useEffect(() => {
    handleSearch(activeSearch);
  }, [categoria]);

  return (
      <AuthenticatedPopupLayout>
        <FlexCard>
          <LogsTable
              dataSource={dataSource}
              loading={loadingSearch}
              miniTableRef={miniTableRef}
              title={title}
              showAcesso={showAcesso}
              showEntidade={showEntidade}
          />
        </FlexCard>
      </AuthenticatedPopupLayout>);
}

import React, { useMemo } from 'react';
import InputName from "../input-name";
import { Form } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ActionButton from "../action-button";
import styles from './_styles.less';
import ActionBar from "../action-bar";
import { useTranslation } from "react-i18next";

export default function InputConsultivoAreaTipoTrabalhoFases({ name, onChange, value: values = [], ...props }) {
  const { t } = useTranslation();

  const id = useMemo(() => "input-consultivo-area-tipo-trabalho-fases-" + new Date().getTime(), []);
  const fireChange = (newValue, value, index) => {
    if (newValue === value)
      return;
    if (typeof onChange === 'function')
      onChange(values
        .map((v, i) => i === index ? newValue : v)
        .filter(v => v !== null));
  };

  const handleChange = (newValue, value, index) => fireChange(newValue, value, index);

  const handleAdd = (index, defaultValue = {}) => {
    if (typeof onChange === 'function') {
      const before = values.slice(0, index + 1);
      const after = values.slice(index + 1);
      onChange([...before, defaultValue, ...after]);
      setImmediate(() => document.querySelector(`#${id} tbody tr:nth-child(${index + 1}) input`).focus());
    }
  }

  const handleSwap = (index1, index2) => {
    onChange(values.map((value, index) => {
      if (index === index1)
        return values[index2];
      else if (index === index2)
        return values[index1];
      return value;
    }));
  }

  const handleRemove = index => {
    const before = values.slice(0, index);
    const after = values.slice(index + 1);
    onChange([...before, ...after])
    setImmediate(() => document.querySelector(`#${id} tbody tr:nth-child(${Math.max(0, index)}) input`).focus());
  }

  return (
    <table id={id} className={styles['input-consultivo-area-tipo-trabalho-fases']}>
      <tbody>
      {values.map((value, index) => (
        <tr key={index}>
          <td className={styles['descricao']}>
            <Form.Item name={[name, index, "descricao"]}>
              <InputName
                placeholder={t('Descrição')}
                value={value.descricao}
                onChange={descricao => handleChange({ ...value, descricao }, value, index)}
                {...props}
              />
            </Form.Item>
          </td>
          <td>
            <ActionBar gutter={[0, 0]}>
              <ActionButton disabled={props.disabled || index <= 0} icon={<ArrowUpOutlined/>} onClick={() => handleSwap(index, index - 1)}/>
              <ActionButton disabled={props.disabled || index + 1 >= values.length} icon={<ArrowDownOutlined/>} onClick={() => handleSwap(index, index + 1)}/>
              <ActionButton disabled={props.disabled} icon={<DeleteOutlined/>} onClick={() => handleRemove(index)}/>
            </ActionBar>
          </td>
        </tr>
      ))}
      </tbody>
      <tfoot>
      <tr>
        <td colSpan={3}>
          <Form.Item name={[name, -1]}>
            <ActionBar justify="start">
              <ActionButton disabled={props.disabled} title={t('Adicionar Fase')} noTitle icon={<PlusOutlined/>} onClick={() => handleAdd(values.length)}/>
            </ActionBar>
          </Form.Item>
        </td>
      </tr>
      </tfoot>
    </table>
  );
}

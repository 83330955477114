import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  consultasPorStatus: {},
  consultasPorArea: [],
  consultasPorMes: [],
  consultas: []
};

export const consultasPorStatusSelector = state => state.consultivoDashboard.consultasPorStatus;
export const consultasPorAreaSelector = state => state.consultivoDashboard.consultasPorArea;
export const consultasPorMesSelector = state => state.consultivoDashboard.consultasPorMes;
export const consultasSelector = state => state.consultivoDashboard.consultas;

export const fetchConsultasPorStatus = createAsyncThunk(
  'consultivoDashboard/fetchConsultasPorStatus',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get('/consultivo/dashboard/consultas-por-status');
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchConsultasPorArea = createAsyncThunk(
  'consultivoDashboard/fetchConsultasPorArea',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get('/consultivo/dashboard/consultas-por-area');
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchConsultasPorMes = createAsyncThunk(
  'consultivoDashboard/fetchConsultasPorMes',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get('/consultivo/dashboard/consultas-por-mes');
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchConsultas = createAsyncThunk(
  'consultivoDashboard/fetchConsultas',
  async (_, thunkAPI) => {
    try {
      const { data } = await axios.get('/consultivo/dashboard/consultas');
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const slice = createSlice({
  name: 'consultivoDashboard',
  initialState,
  reducers: {
    resetar: (state) => {
      return initialState;
    },
  },
  extraReducers: {
    [fetchConsultasPorStatus.fulfilled]: (state, action) => {
      state.consultasPorStatus = action.payload;
    },
    [fetchConsultasPorArea.fulfilled]: (state, action) => {
      state.consultasPorArea = action.payload;
    },
    [fetchConsultasPorMes.fulfilled]: (state, action) => {
      state.consultasPorMes = action.payload;
    },
    [fetchConsultas.fulfilled]: (state, action) => {
      state.consultas = action.payload;
    },
  },
});

export default slice.reducer;

export const { resetar } = slice.actions;

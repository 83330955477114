// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".h-tlYXpmwOUcTAaLkjdMPw\\=\\= .XxRXIRIvu9xOR9glwj-AcA\\=\\= {\n  min-width: 384px;\n}\n.h-tlYXpmwOUcTAaLkjdMPw\\=\\= td + td {\n  padding-left: 16px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/consultivo/area/tipos-trabalho/tipo-trabalho/_styles.less"],"names":[],"mappings":"AAAA;EAEI,gBAAA;AAAJ;AAFA;EAMI,kBAAA;AADJ","sourcesContent":[".fases {\n  .descricao {\n    min-width: 384px;\n  }\n\n  td + td {\n    padding-left: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fases": "h-tlYXpmwOUcTAaLkjdMPw==",
	"descricao": "XxRXIRIvu9xOR9glwj-AcA=="
};
export default ___CSS_LOADER_EXPORT___;

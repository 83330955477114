import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../../components/action-bar';
import ActionButton from '../../../../../components/action-button';
import TextItem from '../../../../../components/text-item';
import useFormatter from '../../../../../hooks/useFormatter';
import useLoading from '../../../../../hooks/useLoading';
import { openPopup } from '../../../../../util/window';
import { assuntoSelector, fetchAssunto } from "../../../../../store/consultivoArea";
import { isDiretorSelector } from "../../../../../store/autenticacao";
import TextColor from "../../../../../components/text-color";

export default function ConsultivoAreaAssuntoDetailPage() {
  const { t } = useTranslation();
  const isEditable = useSelector(isDiretorSelector);
  const assunto = useSelector(assuntoSelector);
  const { formatNumber } = useFormatter();
  const loading = useLoading(fetchAssunto.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const areaId = match.params.areaId;
  const id = match.params.id;

  const handleEdit = () => {
    history.replace(`/consultivo/areas/${areaId}/assuntos/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`/consultivo/areas/${areaId}/assuntos`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/consultivo/areas/${areaId}/assuntos/${id}/`, `logs_/consultivo/areas/${areaId}/assuntos/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Descrição')}>{assunto?.descricao}</TextItem></Col>
        <Col flex="200px"><TextColor label={t('Cor')}>{assunto?.cor}</TextColor></Col>
      </Row>
    </Spin>
  );
}
